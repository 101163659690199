
import Vue from "vue";
import { Survey } from "@/models/survey";
import i18n from "@/i18n";
import axios from "@/plugins/axios";

export default Vue.extend({
  name: "DDCReports",
  data: () => ({
    surveys: [] as Survey[],
  }),
  computed: {
    selectedSurvey: {
      get() {
        return this.$store.getters["ddcreport/getYearSelected"];
      },
      set(year) {
        return this.$store.dispatch("ddcreport/changeYearSelected", year);
      },
    },
    reportList() {
      return [
        {
          name: i18n.t("reports.ddcreports.listReports.datasourceinformation"),
          nested: false,
          to: { name: "datasourceinformation" },
        },
        {
          name: i18n.t("reports.ddcreports.listReports.datasourceAllData"),
          nested: false,
          to: { name: "datasourceAllData" },
        },
        {
          name: i18n.t("reports.ddcreports.listReports.hemophiliavWDbyGender"),
          nested: false,
          to: { name: "hemophiliavWDbyGender" },
        },
        {
          name: i18n.t(
            "reports.ddcreports.listReports.rareBlendingDisorderbyGender"
          ),
          nested: false,
          to: { name: "rareBlendingDisorderbyGender" },
        },
        {
          name: i18n.t("reports.ddcreports.listReports.hemophiliavWDbyAge"),
          nested: false,
          to: { name: "hemophiliavWDbyAge" },
        },
        {
          name: i18n.t("reports.ddcreports.listReports.hemophiliaA&BSeverity"),
          nested: false,
          to: { name: "hemophiliaA&BSeverity" },
        },
        {
          name: i18n.t("reports.ddcreports.listReports.vWD-Severity"),
          nested: false,
          to: { name: "vWD-Severity" },
        },
        {
          name: i18n.t(
            "reports.ddcreports.listReports.hemophiliavWDInhibitors"
          ),
          nested: false,
          to: { name: "hemophiliavWDInhibitors" },
        },
        {
          name: i18n.t("reports.ddcreports.listReports.hemophiliaProductUse"),
          nested: false,
          to: { name: "hemophiliaProductUse" },
        },
        {
          name: i18n.t("reports.ddcreports.listReports.vWDProductUse"),
          nested: false,
          to: { name: "vWDProductUse" },
        },
        // {
        //   name: i18n.t("reports.ddcreports.listReports.HIV-HCV-Infection"),
        //   nested: false,
        //   to: {name: "HivHcvInfection"}
        // },
        // {
        //   name: i18n.t("reports.ddcreports.listReports.severityHemophiliaByGenderAndGNI"),
        //   nested: false,
        //   to: {name: "severityHemophiliaByGenderAndGNI"}
        // },
        {
          name: i18n.t("reports.ddcreports.listReports.HIV-HCV"),
          nested: false,
          to: { name: "hiv_hcv" },
        },
        {
          name: i18n.t("reports.ddcreports.listReports.death"),
          nested: false,
          to: { name: "deaths" },
        },
        {
          name: i18n.t("reports.ddcreports.listReports.care"),
          nested: false,
          to: { name: "care" },
        },
        {
          name: i18n.t("reports.ddcreports.listReports.treatment"),
          nested: false,
          to: { name: "treatment" },
        },
        {
          name: i18n.t("reports.ddcreports.listReports.treatmentAllData"),
          nested: false,
          to: { name: "treatmentAllData" },
        },
      ];
    },
  },
  methods: {},
  created() {
    if (this.$route.name !== "DDCReports") {
      this.$router.push({ name: "DDCReports" });
    }
    axios
      .get(`/survey/all/?beforeCurrentDate=${false}`)
      .then((res) => {
        this.surveys = res.data;
        if (this.surveys.length > 0 && this.selectedSurvey.id == undefined)
          this.selectedSurvey = this.surveys[0];
      })
      .catch((e) => console.log(e));
  },
  watch: {},
});
